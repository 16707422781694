import { Injectable, NgZone, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import * as _ from 'lodash';
import {
    Observable,
    Subject,
    animationFrameScheduler,
    distinctUntilChanged,
    from,
    observeOn,
    pluck,
    shareReplay,
    takeUntil,
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocationService implements OnDestroy {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ngZone = inject(NgZone);

    private readonly onDestroy$: Subject<boolean> = new Subject<boolean>();

    /**
     * One of lifecycle hooks of Angular. Called when the component is destroyed.
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    public get queryParams(): Params {
        return this.route.snapshot.queryParams;
    }

    public set queryParams(parameters: Params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: parameters,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    public navigateTo$(
        pathNames: string[],
        queryParams: Params = {},
        queryParamsHandling: QueryParamsHandling = '',
    ): Observable<boolean> {
        if (queryParamsHandling === '') {
            queryParamsHandling = _.startsWith(window.location.pathname, '/' + _.first(pathNames))
                ? 'merge'
                : '';
        }
        return from(
            this.router.navigate(pathNames, {
                queryParams: queryParams,
                relativeTo: this.route,
                queryParamsHandling,
            }),
        ).pipe(takeUntil(this.onDestroy$));
    }

    public observeQueryParameter$<T = string>(parameterName: string): Observable<T> {
        return this.route.queryParams.pipe(
            observeOn(animationFrameScheduler),
            pluck(parameterName),
            distinctUntilChanged(),
            shareReplay(1),
            takeUntil(this.onDestroy$),
        );
    }

    public getQueryParameter<T = unknown>(key: string, dflt?: string): T {
        return _.get(this.route.snapshot.queryParams, key, dflt) as T;
    }

    public setQueryParameter$(key: string, value: unknown, pushToHistory = true): Observable<boolean> {
        return from(this.setQueryParameter(key, value, pushToHistory));
    }

    public setQueryParameter(key: string, value: unknown, pushToHistory = true): Promise<boolean> {
        const parameter: Params = {};
        parameter[key] = value;
        return this.updateQueryParameters(parameter, {
            skipLocationChange: !pushToHistory,
        });
    }

    public deleteQueryParameter(...names: string[]): Promise<boolean> {
        const currentQueryParams = this.route.snapshot.queryParams;
        const parameters = _.pickBy(currentQueryParams, (value, key) => !_.includes(names, key));
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: parameters,
            replaceUrl: true,
        });
    }

    public deleteQueryParameter$(...names: string[]): Observable<boolean> {
        return from(this.deleteQueryParameter(...names)).pipe(
            observeOn(animationFrameScheduler),
            shareReplay(1),
            takeUntil(this.onDestroy$),
        );
    }

    public hasQueryParameter(key: string): boolean {
        return _.has(this.route.snapshot.queryParams, key);
    }

    public updateQueryParameters(
        queryParams: Params,
        {
            queryParamsHandling = 'merge',
            skipLocationChange = false,
        }: {
            queryParamsHandling?: QueryParamsHandling | null;
            skipLocationChange?: boolean;
        } = {},
    ): Promise<boolean> {
        return this.ngZone.run(() => {
            return this.router.navigate([], {
                relativeTo: this.route,
                queryParams: queryParams,
                // replaceUrl: true,
                queryParamsHandling: queryParamsHandling,
                skipLocationChange: skipLocationChange,
            });
        });
    }
}
